import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import "../css/contact.css";
import "../css/default.css";
import Navbar from "../components/navbar/navbar.js";

function Contact({ location }) {
    return (
        <div className="contact-wrapper">
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <title>Contact | Contrarian Capital</title>
            </Helmet>

            <Navbar location={location} />

            <div className="contact-container">
                <div className="contact-head">
                    <h1>Contact</h1>
                    <p>
                        The best way to reach out is to send an email to{" "}
                        <strong>
                            <a
                                href="mailto:patrekur@myntkaup.is"
                                target="_blank"
                                rel="noreferrer"
                            >
                                patrekur@myntkaup.is
                            </a>
                        </strong>
                        .
                    </p>
                </div>

                <div className="contact-location">
                    <div className="contact-location-text">
                        <p>Bjargargötu 1, 102 Reykjavík</p>
                        <p>
                            <strong>Contrarian Capital ehf.</strong>
                        </p>
                    </div>

                    <a
                        href="https://goo.gl/maps/8gFgTfxkcJ46xVFV7"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="location-container">
                            <StaticImage
                                src="../images/location.png"
                                alt="Map of office location"
                            />

                            <div className="location-overlay">
                                <div className="location-overlay-shadow">
                                    <h3>View in Maps</h3>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
